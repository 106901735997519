/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useRef } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TestMonialImage from '../../asset/image/testmonials/user.png';
import TestMonialImage2 from '../../asset/image/group/starrating.png';
import backgroundImage from '../../asset/image/background/FlagBG.png';

const Testimonials = () => {
    const sliderRef = useRef(null);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    const backgroundStyle = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100%', // Adjust the height as needed
        width: '100%',
        margin: 'auto auto',
    };

    const backgroundColor = {
        backgroundColor: "rgb(241 245 249)",
        opacity: "80%"
    }

    return (
        <div style={backgroundStyle}>
            <div style={backgroundColor} className='py-10'>
                <div className="max-w-screen-2xl mx-auto px-3 py-10 relative">
                    <div className="flex justify-between items-center">
                        <div className='p-7'>
                            <h2 className='text-3xl font-bold text-[#FE9901]'>Testimonials</h2>
                            <p className='text-xl pt-2'>See what our happy and satisfied customers have to say!</p>
                        </div>

                        {/* Navigation Buttons */}
                        <div className="absolute top-0 right-0 mt-3">
                            <button
                                className="bg-red-400 mx-5 hover:bg-red-300 text-gray-800 font-bold py-2 px-2 rounded-full"
                                onClick={() => sliderRef.current.slickPrev()}
                            >
                                <svg class="h-8 w-8 text-white  " width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <polyline points="15 6 9 12 15 18" /></svg>
                            </button>
                            <button
                                className="bg-red-400 mx-5 hover:bg-red-300 text-gray-800 font-bold py-2 px-2 rounded-full"
                                onClick={() => sliderRef.current.slickNext()}
                            >
                                <svg class="h-8 w-8 text-white" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <polyline points="9 6 15 12 9 18" /></svg>
                            </button>
                        </div>
                    </div>

                    {/* Slider Section */}
                    <Slider ref={sliderRef} {...settings}>
                        <div className='px-4 focus:outline-none focus:border-none focus:shadow-none relative pt-10'>
                            <img src={TestMonialImage} className='absolute w-64 left-4 top-[-75px]' alt="Testmonial" />
                            <img src={TestMonialImage2} className='absolute w-48 left-12 top-[130px]' alt="Testmonial" />
                            <iframe src="https://drive.google.com/file/d/1Q-oEqYaDwHhFzVZEaneAWbjlD1MWZxkP/preview" width="640" height="480" className='rounded-[40px] h-[400px]' allow="autoplay"></iframe>
                        </div>

                        <div className='px-4 focus:outline-none focus:border-none focus:shadow-none relative pt-10'>
                            <img src={TestMonialImage} className='absolute w-64 left-4 top-[-75px]' alt="Testmonial" />
                            <img src={TestMonialImage2} className='absolute w-48 left-12 top-[130px]' alt="Testmonial" />
                            <iframe src="https://drive.google.com/file/d/1QCFsO_QXut6fCS6oBr3x7_j5M2hL0dLv/preview" width="640" height="480" className='rounded-[40px] h-[400px]' allow="autoplay"></iframe>
                        </div>

                        <div className='px-4 focus:outline-none focus:border-none focus:shadow-none relative pt-10'>
                            <img src={TestMonialImage} className='absolute w-64 left-4 top-[-75px]' alt="Testmonial" />
                            <img src={TestMonialImage2} className='absolute w-48 left-12 top-[130px]' alt="Testmonial" />
                            <iframe src="https://drive.google.com/file/d/1vAu-_pc8YKjQzED0D4yOCjjg-y0XRYoW/preview" width="640" height="480" className='rounded-[40px] h-[400px]' allow="autoplay"></iframe>
                        </div>
                  
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default Testimonials;
