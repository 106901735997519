import './App.css';
import Home from './page/home/Home';
import { Route, Routes, useLocation } from 'react-router-dom';
import MarketingConsultant from './page/MarketingConsultant/MarketingConsultant';
import 'flowbite';
import BlogPage from './page/BlogPage/BlogPage';
import ContactPage from './page/ContactPage/ContactPage';
import { useEffect } from 'react';
import { initFlowbite } from 'flowbite';
import MarketingServices from './page/MarketingServices/MarketingServices';
import ReputationManagement from './page/ReputationManagement/ReputationManagement';

function App() {

  const location = useLocation();

  useEffect(() => {
    // Reinitialize Flowbite JS components on every route change
    initFlowbite();
  }, [location]);

  return (


    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/marketing-consultant" element={<MarketingConsultant />} />
      <Route path="/seo-services" element={<MarketingServices />} />
      <Route path="/reputation-management" element={<ReputationManagement />} />
      <Route path="/blog" element={<BlogPage />} />
      <Route path="/contact" element={<ContactPage />} />
    </Routes>

  );
}

export default App;
