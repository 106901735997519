/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Logo from "../../asset/image/logo.png";
import { Link } from 'react-router-dom';

const Navbar = () => {
    return (
        <nav class="fixed w-full z-20 top-0 start-0 py-2">
        <div class="max-w-screen-2xl flex flex-wrap items-center justify-between mx-auto p-4">
            <a href="/" class="flex items-center space-x-3 rtl:space-x-reverse">
                <img src={Logo} class="h-20" alt="Flowbite Logo" />
            </a>
            <button data-collapse-toggle="navbar-multi-level" type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-multi-level" aria-expanded="false">
                <span class="sr-only">Open main menu</span>
                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15" />
                </svg>
            </button>
            <div class="hidden w-full md:block md:w-auto" id="navbar-multi-level">
                <ul class="flex flex-col lg:gap-0 items-center p-4 md:p4 mt-4 border border-gray-100 bg-customBlue md:space-x-4 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-customBlue md:bg-opacity-75 dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700" style={{ borderRadius: "50px" }}>
                    <li className='lg:px-2'>
                        <Link to="/" class="flex items-center px-4 py-2 bg-[#FE9901] hover:bg-[#FE9901] text-white text-sm font-light rounded-full transition text-sm">
                            <svg class="w-5 h-5 mr-1 text-white text-sm" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                <path fill-rule="evenodd" d="M11.293 3.293a1 1 0 0 1 1.414 0l6 6 2 2a1 1 0 0 1-1.414 1.414L19 12.414V19a2 2 0 0 1-2 2h-3a1 1 0 0 1-1-1v-3h-2v3a1 1 0 0 1-1 1H7a2 2 0 0 1-2-2v-6.586l-.293.293a1 1 0 0 1-1.414-1.414l2-2 6-6Z" clip-rule="evenodd" />
                            </svg>
                            <span>Home</span>
                        </Link>

                    </li>
                    <li className='lg:px-2'>
                        <Link to="/marketing-consultant" class="flex items-center px-4 py-2 hover:bg-[#FE9901] text-white text-sm font-light rounded-full transition text-sm">Marketing Consultant</Link>
                    </li>

                    <li className='lg:px-2'>
                        <button id="dropdownNavbarLink" data-dropdown-toggle="dropdownNavbar" class="flex items-center justify-between w-full py-2 px-3 text-white text-sm hover:bg-[#FE9901] md:hover:bg-transparent md:border-0 md:hover:text-white text-sm md:p-0 md:w-auto font-light text-sm">Marketing Services <svg class="w-2.5 h-2.5 ms-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4" />
                        </svg></button>

                        <div id="dropdownNavbar" class="z-10 hidden font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-52 dark:bg-gray-700 dark:divide-gray-600">
                            <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownLargeButton">
                                <li>
                                    <Link to="/seo-services" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white text-sm">Seo Services</Link>
                                </li>

                                <li>
                                    <Link to="/reputation-management" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white text-sm">Reputation Management</Link>
                                </li>

                                <li>
                                    <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white text-sm">Marketing Services 3 </a>
                                </li>

                                <li>
                                    <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white text-sm">Marketing Services 4</a>
                                </li>
                              
                            </ul>
                          
                        </div>
                    </li>

                    
                    <li className='lg:px-2'>
                        <button id="dropdownNavbarLink" data-dropdown-toggle="dropdownNavbar2" class="flex items-center justify-between w-full py-2 px-3 text-sm text-white text-sm hover:bg-[#FE9901] md:hover:bg-transparent md:border-0 md:hover:text-white text-sm md:p-0 md:w-auto font-light">Who We Serve <svg class="w-2.5 h-2.5 ms-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4" />
                        </svg></button>

                        <div id="dropdownNavbar2" class="z-10 hidden font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
                            <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownLargeButton">
                                <li>
                                    <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white text-sm">Areas Served 1</a>
                                </li>

                                <li>
                                    <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white text-sm">Areas Served 2 </a>
                                </li>

                                <li>
                                    <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white text-sm">Areas Served 3 </a>
                                </li>

                                <li>
                                    <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white text-sm">Areas Served 4</a>
                                </li>
                              
                            </ul>
                          
                        </div>
                    </li>
               
                    <li className='lg:px-2'>
                        <button id="dropdownNavbarLink" data-dropdown-toggle="dropdownNavbar3" class="flex items-center justify-between w-full py-2 px-3 text-white text-sm hover:bg-[#FE9901] md:hover:bg-transparent md:border-0 md:hover:text-white text-sm md:p-0 md:w-auto font-light">Areas Served <svg class="w-2.5 h-2.5 ms-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4" />
                        </svg></button>

                        <div id="dropdownNavbar3" class="z-10 hidden font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
                            <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownLargeButton">
                                <li>
                                    <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white text-sm">Areas Served 1</a>
                                </li>

                                <li>
                                    <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white text-sm">Areas Served 2 </a>
                                </li>

                                <li>
                                    <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white text-sm">Areas Served 3 </a>
                                </li>

                                <li>
                                    <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white text-sm">Areas Served 4</a>
                                </li>
                              
                            </ul>
                          
                        </div>
                    </li>
             
                    <li className='lg:px-2'>
                        <Link to="/blog" class="flex items-center px-4 py-2 hover:bg-[#FE9901] text-white text-sm font-light rounded-full transition">Blogs</Link>
                    </li>
             
                    <li className='lg:px-2'>
                        <Link to="/contact" class="flex items-center px-4 py-2 hover:bg-[#FE9901] text-white text-sm font-light rounded-full transition">Contact Us</Link>
                    </li>

                    <li className='lg:px-2'>
                        <a href="#" class="flex items-center px-4 py-2 hover:bg-[#FE9901] text-white text-sm font-light rounded-full transition"><svg class="w-6 h-6 mr-2 text-white text-sm dark:text-white text-sm" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18.427 14.768 17.2 13.542a1.733 1.733 0 0 0-2.45 0l-.613.613a1.732 1.732 0 0 1-2.45 0l-1.838-1.84a1.735 1.735 0 0 1 0-2.452l.612-.613a1.735 1.735 0 0 0 0-2.452L9.237 5.572a1.6 1.6 0 0 0-2.45 0c-3.223 3.2-1.702 6.896 1.519 10.117 3.22 3.221 6.914 4.745 10.12 1.535a1.601 1.601 0 0 0 0-2.456Z" />
                        </svg> <span>941 457 7427</span></a>
                    </li>


                </ul>
            </div>


        </div>
    </nav>
    );
};

export default Navbar;